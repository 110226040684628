/* syntax déclaration de variable

$variable : #000;

*/

$transition: .25s ease-in-out;

// VARIABLE DE TAILLE
$dix: 1rem;
$onze: 1.1rem;
$douze: 1.2rem;
$treize: 1.3rem;
$quatorze: 1.4rem;
$quinze: 1.5rem;
$seize: 1.6rem;
$dixhuit: 1.8rem;
$vingt: 2rem;
$vingt-un: 2.1rem;
$vingt-deux: 2.2rem;
$vingt-cinq: 2.5rem;
$vingt-huit: 2.8rem;
$vingt-neuf: 2.9rem;
$trente: 3.0rem;

$default-global-size: 1rem;
$global-sizes: ();
$global-sizes: map-merge(
    (
        8: ($default-global-size * .8),
        14: ($default-global-size * 1.4),
        12: ($default-global-size * 1.2),
        16: ($default-global-size * 1.6),
        18: ($default-global-size * 1.8),
        20: ($default-global-size * 2),
        24: ($default-global-size * 2.4),
        26: ($default-global-size * 2.6),
        28: ($default-global-size * 2.8),
        30: ($default-global-size * 3.0),
        32: ($default-global-size * 3.2),
        36: ($default-global-size * 3.6),
        40: ($default-global-size * 4),
        44: ($default-global-size * 4.4),
        48: ($default-global-size * 4.8),
        56: ($default-global-size * 5.6),
    ),
    $global-sizes
);

$spacer: 1rem;
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
);
$spacers: map-merge(
    $global-sizes,
    $spacers
);

// stylelint-disable-next-line scss/dollar-variable-default

// - Module bandeau slick
$carousel-height : 500px;

// VARIABLE DE COULEUR
$white: #FFFFFF;
$grey: #333333;
$grey2: #ECEDF0;
$grey3: #99A0AF;
$beige: #F8F8F8;
$beige1: #E8E8EB;
$blue: #053F64;
$blue2:#000054;
$blue3:#0059A9;
$blue-opacity: rgba(5, 63, 100, 0.7803921568627451);
$blue-light: #00C2DA;
$blue-light1: #26ABDA;
$blue-light3: #A6DDF3;
$orange: #F48400;
$jaune: #FAC700;
$purple: #30305C;
$purple1: #393961;
$purple2: #515162;
$purple3: #2C2C53;
$purple4: #67678B;
$purple5: #2c2c54;
$purple-light: #A2A2B5;
$purple-light1: #595959;
$purple-dark: #3F3F47;

$primary:$blue;
$action: $blue-light1;
$timeline-state-default: $blue-light3;
$timeline-state-active: $blue3;

$theme-colors: (
  'gray' : $beige1,
  'action' : $action,
);

$background-color-default: $white;
$background-color-action: $action;
$background-color-aside-nav: $blue-light1;
$background-color-accent: #E6F8FF;
$background-color-contrast: $purple;
$input-border-color: $beige1;
$border-color-action: $action;
$content-color-default: $purple-light;
$content-color-dark: $purple;
$content-color-body: $purple-dark;
$content-color-action: $action;

$border-color-default: $input-border-color;
$input-placeholder-color: #B8B8C4;