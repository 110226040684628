.view-search {
  .corps {
    background-color: $beige;
    @include media-breakpoint-up(md) {
      padding: 4rem;
    }
    h1 {
      font-family: robotobold;
      font-size: $vingt-cinq;
      line-height: 29px;
      color: $purple1;
      margin-bottom: 2rem;
    }

    form {
      border-bottom: 1px solid $beige1;
      button {
        font-family: robotoregular;
        line-height: 15px;
        padding: 7px 18px;
        border: 1px solid $blue-light1;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
        display: block;
        width: 100%;
        transition: all 0.3s ease;
        background: linear-gradient(45deg, #26ABDA 0%, #00C2DA 100%);
        color: $white;
        margin-top: 0;
        &:hover {
          opacity: 0.6;
        }
      }

      strong {
        color: $blue-light1;
        font-size: $seize;
        font-family: robotobold;
        line-height: 19px;
        margin-bottom: 2rem;
        font-weight: 500;
      }

      .searchintro {
        strong {
          margin: 0;
        }
      }

      .phrases-box {
        input, label {
          margin-right: 10px;
          cursor: pointer;
          font-size: $quinze;
        }
      }

      .btn-group {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      .inputbox {
        font-family: robotoregular;
        font-size: $quinze;
        color: $purple-light;
        line-height: 18px;
        padding: 7px 15px;
        border-radius: 4px 0 0 4px;
        border: 1px solid $beige1;
        outline: none;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
        &::placeholder {
          color: $purple-light;
        }
      }

      .recherche-precise {
        border-top: 1px solid $beige1;
        .inputbox {
          cursor: pointer;
          padding: 2px 18px;
        }
        .label-style {
          color: $purple1;
          font-size: $quinze;
          font-family: robotobold;
          line-height: 18px;
          margin-bottom: 1rem;
        }
      }
    }

    .search-results {
      margin: 30px 0;
      .result-text {
        font-size: $quatorze;
        .highlight {
          color: $blue-light1;
        }
      }

      .result-title {
        font-size: $quinze;
        a {
          color: $purple1;
          text-decoration: underline;
        }
      }
    }

    .pagination {
      span {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: $purple-light;
        background-color: $beige1;
        border: 1px solid $beige;
      }

      .active {
        span {
          background-color: $blue-light;
          color: $white;
        }
      }
    }
  }

  #finder-search {
    .word {
      display: flex;
      width: 100%;
      label {
        display: none;
      }

      input {
        width: 80%;
        height: 40px;
      }

      button {
        flex: 1;
      }
    }
  }

  .finder {
    @include media-breakpoint-up(lg) {
      width: 60%;
      margin: auto;
    }
  }

  .search-pagination {
    text-align: center;
  }
}

.moduletable_search-faq {
  display: flex;
  justify-content: center;
  background: $purple;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  > div {
    width: 100%;
    input {
      width: 80%;
      height: 40px;
    }

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      span {
        color: $white;
      }
    }
  }
}