// Page categories

.view-article, .view-category {
  .corps {
    background-color: $beige;
  }
}

.layout-type-blog, .view-article {
  .corps {
    .main {
      background-color: $beige;
      padding: 40px 15px;
      @include media-breakpoint-up(xl) {
        padding: 40px 95px;
      }
      @include media-breakpoint-up(md) {
        margin-left: 15px;
        padding: 40px 0;
      }
      .subheading-category {
        display: block;
        color: $purple1;
        font-size: $vingt-cinq;
        font-family: robotobold;
        margin-bottom: 20px;
      }

      .category-desc {
        color: $purple1;
        font-family: robotoregular;
        font-size: $quinze;
        margin-bottom: 40px;
      }

      .block {
        padding: 20px;
        height: 250px;
        background-size: cover !important;
        text-decoration: none;
        box-shadow: 0 10px 30px 0 rgba(0,0,0,0.2);
        border-radius: 4px;
        margin-bottom: 30px;
        position: relative;
        //width: 48.5%;
        display: block;
        overflow: hidden;
        transition: all 0.3s ease;
        //&:first-child, &:nth-child(3) {
        //  margin-right: 30px;
        //}

        &:before {
          content: "";
          z-index: 0;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 101%;
          background: linear-gradient(180deg, #26ABDA 0%, rgba(0,194,218,0.5) 100%);
          border-radius: 4px;
        }

        &:hover {
          box-shadow: 0 10px 30px 0 rgba(0,0,0,0.5);
          span {
            i {
              left: 5px;
            }
          }
        }

        h3, p {
          color: $white !important;
          position: relative;
        }

        h3 {
          font-family: robotobold;
          font-size: $vingt !important;
          line-height: 24px;
          margin-bottom: 15px;
        }

        p {
          font-size: $quinze;
          font-family: robotoregular;
          line-height: 18px;
        }

        span {
          font-size: $treize;
          color: $white;
          font-family: robotoregular;
          line-height: 15px;
          position: absolute;
          bottom: 20px;
          right: 40px;
          i {
            position: relative;
            left: 0;
            margin-left: 5px;
            transition: all 0.3s ease;
          }
        }
      }
    }
  }
}
