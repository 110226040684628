.com_rsform {
  .corps {
    background-color: $beige;
    padding: 0 0 2rem;
    overflow: hidden;

    .rsform-thankyou-button {
      display: table;
      margin: auto;
      font-family: robotoregular;
      line-height: 15px;
      padding: 18px;
      border: 1px solid $blue-light1;
      border-radius: 4px;
      cursor: pointer;
      display: block;
      transition: all 0.3s ease;
      background: linear-gradient(45deg, #26ABDA 0%, #00C2DA 100%);
      color: $white;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  #userForm {
    .rsformProgress {
      @include make-col-ready();
      @include media-breakpoint-up(sm) {
        @include make-col(6);
      }
      margin: 20px auto;
      padding: 0;
      height: 80px;

      ol {
        padding: 0;
        margin: 0;
        list-style-type: none;
        counter-reset: repas;
        display: flex;
        @include media-breakpoint-up(sm) {
          justify-content: center;
        }
        @include media-breakpoint-down(sm) {
          justify-content: space-between;
        }

        &:before {
          content: '';
          display: block;
          width: 100%;
          border-bottom: 5px solid $blue;
          position: absolute;
          @include media-breakpoint-up(sm) {
            top: 20px;
            left: 8px;
          }
          @include media-breakpoint-down(sm) {
            top: 15px;
          }
        }

        li {
          counter-increment: repas;
          @include media-breakpoint-up(sm) {
            flex-grow: 1;
          }
          position: relative;
          text-align: center;

          &:last-child {
            flex-grow: 0;
          }

          &.actif {
            @include media-breakpoint-down(sm) {
              &:before {
                background-color: $blue-light;
              }
            }

            span {
              &:before {
                background-color: $blue-light;
              }
            }
          }

          @include media-breakpoint-down(sm) {
            &:before {
              content: counter(repas);
              background-color: $blue;
              display: table;
              margin: auto;
              padding: 8px 15px;
              color: $white;
              border-radius: 28px;
            }
          }

          span {
            position: absolute;
            top: 5px;
            left: -15%;
            color: $purple2;
            font-size: $dix;

            strong {
              color: $blue2;
              font-size: $quatorze;
            }

            &:before {
              content: counter(repas);
              background-color: $blue;
              display: table;
              margin: auto;
              padding: 8px 15px;
              color: $white;
              border-radius: 28px;
              font-size: $quatorze;
            }

            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
        }
      }
    }

    h2 {
      padding: 0;
      font-family: robotobold;
      font-size: $vingt-cinq;
      line-height: 29px;
      color: $purple1;
      margin-bottom: 2rem;
    }

    h3 {
      padding: 0;
      font-family: robotobold;
      font-size: $trente;
      line-height: 29px;
      color: $purple1;
      margin-bottom: 0;
    }

    h4 {
      padding: 0;
      font-family: robotobold;
      font-size: $vingt-cinq;
      color: $blue-light1;
      margin-bottom: 2rem;
    }

    fieldset {
      @include make-col-ready();
      @include media-breakpoint-up(sm) {
        @include make-col(6);
        margin: auto;
      }
      padding: 0;

      &.formHidden {
        display: none;
      }
    }

    .formRed {
      @include make-col-ready();
      @include media-breakpoint-up(sm) {
        @include make-col(6);
        margin: auto;
      }
      padding: 0 0 15px;
    }

    .rsform-block-bandeau {
      @extend %centpourcent;
      text-align: center;

      img {
        border-radius: 0 0 4px 4px;
        @include media-breakpoint-up(md) {
          height: 250px;
        }
      }
    }

    .form-group {
      margin-bottom: 2rem;
      font-family: robotoregular;
      font-size: $quinze;
      color: $purple1;
      line-height: 18px;

      a {
        color: $blue-light;
      }

      label {
        color: $purple1;
        font-size: $quinze;
        font-family: robotobold;
        line-height: 18px;
        margin-bottom: 1rem;
      }

      .form-check {
        label {
          color: $purple1;
          font-size: $quinze;
          font-family: robotoregular;
          line-height: 17px;
          @include media-breakpoint-down(sm) {
            font-size: $quatorze;
          }
        }
      }

      .formControls {
        input[type="text"] {
          width: 100%;
          font-family: robotoregular;
          font-size: $quinze;
          color: $grey;
          line-height: 18px;
          padding: 10px 15px;
          border-radius: 4px;
          border: 1px solid $beige1;
          outline: none;
          height: auto;

          &::placeholder {
            color: $purple-light;
          }
        }

        .form-check-input {
          position: static;
          margin-right: 8px;
        }

        button {
          font-family: robotoregular;
          line-height: 15px;
          padding: 18px;
          border: 1px solid $blue-light1;
          border-radius: 4px;
          cursor: pointer;
          display: block;
          transition: all 0.3s ease;
          background: linear-gradient(45deg, #26ABDA 0%, #00C2DA 100%);
          color: $white;

          &:hover {
            opacity: 0.6;
          }

          & + button {
            margin-left: 15px;

            &.rsform-submit-button {
              float: right;
            }
          }

          &.rsfp-field-multiple-plus-button {
            padding: 8px 18px;
            order: 1;
            margin: 10px 0;
          }
        }
      }
    }
  }
}

#rsform_52_page_0,
#rsform_55_page_0 {
  margin-top: 10px;

  @include media-breakpoint-up(md) {
    background: url('/images/sampledata/nautile-bg.png') no-repeat;
    background-size: contain;
    background-position: center;
    padding: 20px;
  }

  h3 {
    color: $purple5;
    font-family: robotoblack;
    font-size: $vingt-neuf;
  }

  p {
    font-size: $vingt-un;
    color: $purple5;
    line-height: 25px;
  }

  hr {
    background-color: $purple-light1;
  }

  label {
    color: $purple5;
    font-family: robotobold;
    font-size: $seize;
  }

  .form-check-label {
    font-size: $seize;
    font-family: robotobold;

    input {
      margin-right: 10px;
    }
  }

  .rsform-block-donnee-obligatoire {
    p {
      font-size: $seize;
      font-family: robotoitalic;
    }
  }

  .form-check {
    margin-right: 20px;

    &:last-child {
      margin-right: initial;
    }
  }

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  textarea {
    border-color: $purple-light1;
    padding: 23px 10px;
    border-radius: initial;
  }

  .rsform-block-informations {
    p {
      margin-bottom: 0;
    }
  }

  .rsform-block-rgpd {
    p {
      font-size: $quinze;
      color: $purple5;
      font-family: robotoitalic;
      font-weight: 100;
      line-height: initial;

      a {
        color: $blue-light1;
      }
    }
  }

  .rsform-submit-button {
    padding: 5px 85px;
    background-color: $purple5;
    font-family: robotoblack;
    font-size: $vingt-huit;
    border-radius: 7px;
    @include media-breakpoint-down(md) {
      padding: 5px 0;
      width: 100%;
    }
  }
}

#rsform_49_page_0 {
  .rsform-block-rgpd {
    p {
      font-size: $quinze;
      font-family: robotoitalic;
      font-weight: bold;
      color: $purple5;
      opacity: initial;

      a {
        color: $blue-light1;
      }
    }
  }
}

#rsform_progress_58 {
  display: none;
}

#rsform_58_page_0, #rsform_58_page_1, #rsform_59_page_0 {
  .form-row {
    .rsform-block-bandeau, .rsform-block-bandeau2 {
      position: initial;
      margin: auto;
      margin-top: 11px;
      margin-bottom: 20px !important;
      width: 100%;

      p {
        margin-bottom: 0;
      }

      img {
        width: 100%;
      }
    }

    .rsform-block-retour, .rsform-block-retour2 {
      a {
        background-color: white;
        position: relative;
        padding: 4px 4px 4px 20px;
        border: none;
        color: $grey3;

        &::before {
          content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><g clip-path="url(%23clip0_726_539)"><path d="M11.25 4.5L6.75 9L11.25 13.5" stroke="%2399A0AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_726_539"><rect width="18" height="18" fill="white"/></clipPath></defs></svg>');
          width: 25px;
          height: 20px;
          position: absolute;
          left: 0px;
          top: 19%;
        }
      }
    }

    .form-group {
      display: flex;
      margin-bottom: 24px;
      flex-direction: column;

      label {
        margin-bottom: 12px;
        font-size: $dixhuit;
      }

      input[type="radio"] {
        border: 1px solid $grey2;
        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
      }
    }

    .rsform-block-titre, .rsform-block-titre2 {
      margin-bottom: 0;

      h2 {
        margin-bottom: 32px;
        line-height: normal;
      }
    }

    .rsform-block-etape1, .rsform-block-etape2 {
      margin-bottom: 32px;

      h4 {
        margin-bottom: 0;
      }

      span {
        font-size: 18px;
      }
    }

    .rsform-block-info {
      margin-bottom: 32px;

      span {
        font-weight: $font-weight-normal;
        font-size: $seize;
      }
    }

    .rsform-block-mobilite-geographique, .rsform-block-type-contrat {
      .formControls .form-check {
        margin-right: 16px;

        input {
          border-radius: 4px;
          border: 1px solid $grey2;
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
        }

        label {
          margin-bottom: 2px;
        }
      }
    }

    .rsform-block-citoyen, .rsform-block-civilite {
      .formControls .form-check {
        margin-right: 16px;

        label {
          margin-bottom: 0;
        }

        input[type="radio"] {
          border: 1px solid $grey2;
          box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
        }
      }
    }

    .rsform-block-envoyer-ma-candidature {
      .formControls {
        display: flex;
        justify-content: center;

        #Envoyer_ma_candidaturePrev {
          color: $purple;
          text-transform: uppercase;
          font-weight: $font-weight-medium;
          background: white;
          border-radius: 4px;
          border: 1px solid $grey2;
        }

        #Envoyer_ma_candidature {
          text-transform: uppercase;
          margin-left: 32px;
        }
      }
    }

    .rsform-block-envoyer {
      .formControls {
        display: flex;
        justify-content: center;
      }
    }

    .rsform-block-donnees-personnelles {
      label {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }

      .formControls .form-check {
        label {
          margin-bottom: 2px;
        }
      }
    }

    .rsform-block-page2 {
      .formControls {
        justify-content: center;
      }
    }
  }
}