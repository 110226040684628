@font-face {
  font-family: 'bnc-simulator';
  src:
          url('../fonts/bnc-simulator.ttf?kvbriw') format('truetype'),
          url('../fonts/bnc-simulator.woff?kvbriw') format('woff'),
          url('../fonts/bnc-simulator.svg?kvbriw#bnc-simulator') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="simulator-icon"], [class*=" simulator-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bnc-simulator' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: map-get($global-sizes, 24);
  color: $content-color-action;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.simulator-icon-other:before {
  content: "\e900";
}
.simulator-icon-project-3:before {
  content: "\e900";
}
.simulator-icon-student:before {
  content: "\e901";
}
.simulator-icon-start-up:before {
  content: "\e902";
}
.simulator-icon-green:before {
  content: "\e903";
}
.simulator-icon-boat:before {
  content: "\e904";
}
.simulator-icon-car:before {
  content: "\e905";
}

.simulator {
  $parent:&;
  $page-header : 112px;
  --page-header: #{$page-header};
  color: $content-color-default;
  &-nav {
    $simulator-nav-background: radial-gradient(83.99% 72.42% at 5.87% 0.00%, rgba(211, 241, 255, 0.00) 0%, rgba(211, 241, 255, 0.20) 26.50%, rgba(41, 172, 219, 0.20) 100%), #E8F8FF;;
    max-width: 20.8%;
    position: sticky;
    top: var(--page-header);
    height: calc(100vh - var(--page-header));
    background: url('/templates/skazy/dist/images/simulator/nav-vector.svg'), $simulator-nav-background;
    background-repeat: no-repeat;
    background-size: cover, 100%;
    background-position: center;
    background-blend-mode: luminosity, normal;

    @include media-breakpoint-down(lg) {
      display: none;
    }

    &-content {
      padding-top: 17.5%;
      padding-left: 22.75%;
      display: flex;
      flex-direction: column;
      gap: map-get($spacers, 32);
    }
    &-title {
      color: $content-color-dark;
      font-size: map-get($global-sizes, 24);
      font-weight: $font-weight-medium;
    }
    &-items {
      list-style: none;
    }
    &-item {
      $simulator-nav-indicator-width: 1.8rem;
      $simulator-nav-item-margin: map-get($spacers, 36);

      --simulator-nav-indicator-content: #{$content-color-default};
      --simulator-nav-indicator-background-before: #{$timeline-state-default};
      --simulator-nav-indicator-background-after: #{$timeline-state-default};

      line-height: 1;
      color: var(--simulator-nav-indicator-content);
      font-weight: $font-weight-medium;
      font-family: 'Inter', sans-serif;
      position: relative;
      display: flex;
      align-items: center;
      gap: map-get($spacers, 28);
      margin-bottom: $simulator-nav-item-margin;
      transition: all ease-in-out .2s;
      &.active {
        --simulator-nav-indicator-content: #{$content-color-dark};
        --simulator-nav-indicator-background-before: #{$timeline-state-active};
      }
      &.clickable {
        cursor: pointer;
        --simulator-nav-indicator-content: #{$content-color-dark};
        --simulator-nav-indicator-background-before: #{$timeline-state-active};
        --simulator-nav-indicator-background-after: #{$timeline-state-active};
      }
      .simulator-nav-indicator{
        $simulator-nav-indicator-line-width: .3rem;
        $simulator-nav-indicator-line-height: calc(#{$simulator-nav-indicator-width} + #{$simulator-nav-item-margin});
        aspect-ratio: 1;
        border-radius: 50%;
        background: var(--simulator-nav-indicator-background-before);
        width: $simulator-nav-indicator-width;
        &:before {
          content: '';
          position: absolute;
          top: ($simulator-nav-item-margin / 2) * -1;
          left: ($simulator-nav-indicator-width - $simulator-nav-indicator-line-width) / 2;
          border-left: $simulator-nav-indicator-line-width solid var(--simulator-nav-indicator-background-before);
          height: $simulator-nav-item-margin / 2;
        }
        &:after {
          content: '';
          position: absolute;
          bottom: ($simulator-nav-item-margin / 2) * -1;
          left: ($simulator-nav-indicator-width - $simulator-nav-indicator-line-width) / 2;
          border-left: $simulator-nav-indicator-line-width solid var(--simulator-nav-indicator-background-after);
          height: $simulator-nav-item-margin / 2;
        }
      }
      &:first-child {
        .simulator-nav-indicator {
          &:before {
            height: 0;
          }
        }
      }
      &.end {
        .simulator-nav-indicator {
          &:after {
            height: 0;
          }
        }
      }
      &.pending {
        .simulator-nav-indicator {
          &:after {
            border-left-style: dashed;
            bottom: ($simulator-nav-item-margin * 0.75) * -1;
            height: $simulator-nav-item-margin * 0.75;
          }
        }
      }
    }
  }
  &-content {
    padding-bottom: 12rem;

    @include media-breakpoint-down(lg) {
      padding-bottom: 18rem;
    }
  }
  &-tooltip {
    $icon-size: 3.2rem;
    $icon-margin: 1.2rem;
    $tooltip-simulator-padding: map-get($spacers, 24);
    border-radius: $border-radius-lg !important;
    font-size: map-get($global-sizes, 14);
    padding: $tooltip-simulator-padding;
    padding-top: $tooltip-simulator-padding + $icon-size + $icon-margin;
    background-color: $background-color-default;
    border: 1px solid $border-color-default;
    color: $content-color-body;
    max-width: 388px;
    &:before {
      content: '';
      aspect-ratio: 1;
      min-width: $icon-size;
      position: absolute;
      top: $tooltip-simulator-padding;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ7SURBVHgB7ZdBbtpAFIb/GZsKqi6IRKiyMzdIVilUVeEETU8Q2EWlUskJCidoFgRl6Zwg3KBUVYm6CjcIWUVNK8GqiWIzr2+QEhkMZExMN+0nWeDhMfP7zZt/PMC/jsAS7J1eOlJZRYAcEjQUJIdKjjpH+Y0+ImIs4N23q01IfBAQ5blBhCEBHRA1Wi+zPcQhoHZ2nvaunx5AyF1EgECuwqjxUFYWCtj7elm0LPuEo9JYAhbRl0T7zcLzNqIKqH7/tQtFLuJAivLhduYYpgLed3/skJAniBHh+6Xmq41OqH26ocYV7sH6zD85iBMu0ETKzh1srQ2DzXI6ziNZNxmciL6Qooq+OL7zULyuo9sb79N084SA8VIzrXaBc15qrr5A6sLsL6I8HmOeAAjxEatGoDx5G6Da/TlYdsmZopdmK5/N3d3fZ6B2NkivenANT4OjrTwk4Pa3t4OI6ELkHo0sN4jtwwkJiAwP3Cpki4cv1reg/T8CSlrOowXw0/cCN0ar4D6cd9CQACVFH38Ja6TCApJJO/JcLov9LNkLCRhbJCFSKpeBIPpBO56oARJwsXo6wZsJAU9guzBEr+e772wuxv6hYDUm+5mi2r1y2ZKN9gNSqgEpOXGoG8VDuK18phJss6eDEqlEzbv235i4opDSeO/Qcz/99JqQD+gCEQJvETNSjvaP8mv9UPus4GZ+vcPzWkFM6L6a27PfC+c6Ie9YriDBmXiEQZEYcn2UdF/zQhZacbOQaSdgldhqjxF1bC64RMrK6WwuijM+mNROB45HXp0r77U+Ec0Z9YLT3eZPN7aDyUwx/O7g3/ibnOK0IpWWvI/48PvLHM3+8wdDDuma13UMZAAAAABJRU5ErkJggg==");
    }
  }
  &-title {
    color: $content-color-dark;
    font-size: clamp(map-get($global-sizes, 26), 1.56vw ,map-get($global-sizes, 30));
    font-weight: $font-weight-semibold;
  }
  &-subtitle {
    font-size: map-get($global-sizes, 18);
  }
  &-label, &-check-label, &-value {
    color: $content-color-dark;
  }
  &-label {
    font-size: map-get($global-sizes, 18);
    font-weight: $font-weight-medium;
  }
  &-value {
    font-size: map-get($global-sizes, 16);
  }
  &-check-label {
    font-size: map-get($global-sizes, 16);
    font-weight: $font-weight-base;
  }
  &-actions {
    background-color: $background-color-default;
    border: 1px solid $border-color-default;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: map-get($spacers, 24) map-get($spacers, 36);
    display: flex;
    align-items: center;
    justify-content: end;
    gap: map-get($spacers, 16);
    @include media-breakpoint-down(lg) {
      justify-content: space-between;
      &.is-final {
        flex-wrap: wrap;
        .btn {
          display: block;
          width: 100%;
        }
      }
    }
  }
  &-proposal{
    &-header {
      --propal-background-img: url('/templates/skazy/dist/images/simulator/consumer-other-illustration.jpg');
      background-color: $background-color-action;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 62.50%) ,var(--propal-background-img);
      background-size: auto, cover;
      aspect-ratio: 170/77;
      width: 100%;
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;

      @include media-breakpoint-down(md) {
        margin-left: -50vw;
        margin-right: -50vw;
        clear: both;
        position: relative;
        right: 50%;
        left: 50%;
        width: 100vw;
      }

      &[data-type="real-estate"] {
        --propal-background-img: url('/templates/skazy/dist/images/simulator/realestate-illustration.jpg');
      }
      &[data-type="boat"] {
        --propal-background-img: url('/templates/skazy/dist/images/simulator/consumer-boat-illustration.jpg');
      }
      &[data-type="car"] {
        --propal-background-img: url('/templates/skazy/dist/images/simulator/consumer-car-illustration.jpg');
      }
      &[data-type="green"] {
        --propal-background-img: url('/templates/skazy/dist/images/simulator/consumer-credit-vert-illustration.jpg');
      }
      &[data-type="start-up"] {
        --propal-background-img: url('/templates/skazy/dist/images/simulator/consumer-startup-illustration.jpg');
      }
      &[data-type="student"] {
        --propal-background-img: url('/templates/skazy/dist/images/simulator/consumer-etudiant-illustration.jpg');
      }
      &[data-type="project-3"] {
        --propal-background-img: url('/templates/skazy/dist/images/simulator/consumer-projet-3-illustration.jpg');
      }
    }
    &-content {
      margin-top: -8.5vw;

      .simulator-proposal-personalized > & {
        margin-top: -12vw;
      }

      .simulator-proposal-loan-capacity > & {
        margin-top: -13.5vw;
      }

      @include media-breakpoint-down(lg) {
        margin-top: -15vw;

        .simulator-proposal-personalized > & {
          margin-top: -20vw;
        }
      }

      @include media-breakpoint-down(md) {
        margin-top: -20vw;
      }

      @include media-breakpoint-down(xs) {
        margin-top: -150px;

        .simulator-proposal-personalized > & {
          margin-top: -150px;
        }
      }
    }
    &-title {
      font-size: clamp(map-get($global-sizes, 24), 1.45vw ,map-get($global-sizes, 28));
      font-weight: $font-weight-medium;
      color: $white;
      margin-bottom: map-get($spacers, 8);
      line-height: 3.6rem;

      @include media-breakpoint-down(md) {
        line-height: 3rem;
      }
    }
    &-subtitle {
      font-size: map-get($global-sizes, 18);
      color: $white;
      margin-bottom: map-get($spacers, 24);
    }
    &-details-title {
      font-size: clamp(map-get($global-sizes, 24), 1.45vw ,map-get($global-sizes, 28));
      font-weight: $font-weight-medium;
      color: $content-color-dark;
      line-height: 3.6rem;
    }
    &-heading-results {
      font-size: clamp(map-get($global-sizes, 20), 1.25vw ,map-get($global-sizes, 24));
      color: $content-color-dark;
      font-weight: $font-weight-medium;
      margin-bottom: map-get($spacers, 16);
    }
    &-label-results {
      font-size: map-get($global-sizes, 18);
      color: $content-color-dark;
    }
    &-value-results {
      font-size: map-get($global-sizes, 18);
      color: $content-color-action;
      font-weight: $font-weight-medium;
      &-val {
        font-size: clamp(map-get($global-sizes, 26), 1.56vw ,map-get($global-sizes, 30));
        font-weight: $font-weight-semibold;
        small {
          font-weight: $font-weight-medium;
          font-size: map-get($global-sizes, 18);
        }
      }
    }
    &-label {
      color: $content-color-body;
      font-size: map-get($global-sizes, 18);
      font-weight: $font-weight-medium;
      line-height: map-get($global-sizes, 24);
    }
    .form-control, .input-group-text {
      color: $content-color-dark;
      line-height: map-get($global-sizes, 24);
      font-size: map-get($global-sizes, 18);
      font-weight: $font-weight-medium;
    }
    &-incitation {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0, rgba(0, 0, 0, 0.40) 100%) ,url("/templates/skazy/dist/images/simulator/proposal-illustration.png");
      background-size: auto, cover;
      border-radius: $border-radius;
      aspect-ratio: 12/5;
      width: 100%;
      color: $white;
      text-align: center;
      padding: 1.45vw 2.9vw ;
      &-title {
        font-size: clamp(map-get($global-sizes, 20), 1.25vw ,map-get($global-sizes, 24));
        font-weight: $font-weight-medium;
        margin-bottom: map-get($spacers, 32);
      }
      &-content {
        font-size: map-get($global-sizes, 18);
      }
    }
  }
}

.view-megasimulateur {
  .uk-modal-dialog {
    .uk-button {
      transition: all .2s ease-in-out;
      border-radius: $border-radius-sm;
    }
    .uk-button-primary {
      background: $background-color-action;
      &:hover {
        background: darken($background-color-action, 20%);
      }
    }
  }
}
