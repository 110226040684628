.clear {
  @extend .clearfix;
}

.u-nowrap {
  white-space: nowrap;
}

.wsmenu-list > li {
  position: relative;
}

p {
  font-size: $quinze;
  line-height: 18px;
  strong {
    font-family: robotobold;
  }

  .bleu {
    color: $blue-light;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.btn-cagnotte{
  border-radius: 4px 0 0 4px;
  padding: 10px;
  background-color: $jaune;
  color: $black;
  &:hover{
    background-color: rgba($jaune,0.8);
  }
}

.rounded{
  border-radius: 4px !important;
}

.bg-banniere{
  background-color: rgba($blue,0.7);
  .card-body{
    h2{
      color: $white;
      font-family: 'robotobold';
      font-size: 3rem;
    }
    p{
      color: rgba($white,0.5);
      font-size: 1.5rem;
      margin: 20px 0 0;
      font-family: 'robotoregular';
    }
  }
}

.btn-banniere{
  @include boutton($white);
  background: linear-gradient(to right, rgba(36, 172, 218, 1) 0%, rgba(1, 193, 218, 1) 100%);
  padding: 15px 25px;
  &:hover{
    color: $white;
  }
}

.stretched-link:after{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.feedback-container {
  display: none;
}

.text-bleu{
  color: $purple1 !important;
}

.fw-bold{
  font-family: 'robotobold';
}

.uk-position-center-right,
.uk-position-center-left {
  z-index: 1;
}

.text-underline {
  text-decoration: underline !important;
}

.text-color-default {
  color: $content-color-default !important;
}

.text-color-action {
  color: $content-color-action !important;
}

.text-color-dark {
  color: $content-color-dark !important;
}

.font-weight{
  &-medium {
    font-weight: $font-weight-medium !important;
  }
  &-semibold {
    font-weight: $font-weight-semibold !important;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.bg-accent {
  background-color: $background-color-accent !important;
}