.loader {
  $p: #{&};

  border: 4px solid $border-color-action;
  aspect-ratio: 1;
  width: map-get($global-sizes, 48);
  border-radius: 50%;
  border-top-color: transparent;
  border-right-style: dashed;
  animation: loader linear 3000ms infinite;

  &-wrapper {
    position:fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255,255,255,0.8);
    place-items: center;
    display:none;
    backdrop-filter: blur(4px);
    &.show {
      display: grid;
    }
  }
}

@keyframes loader {
  to {
    transform: rotate(360deg);
  }
}