// Accordeon.

.accordion-group.panel {
  margin-bottom: 10px !important;
  .arrow {
    color: $white;
    position: absolute;
    right: 15px;
    transition: all 0.3s;
  }

  &.actif {
    .arrow {
      transform: rotate(90deg);
    }
  }
}

.accordion-toggle.rl_sliders-toggle {
  background: linear-gradient(to right, #24acda 0%, #01c1da 100%);
  border-radius: 4px;
}

.rl_sliders-toggle-inner.nn_sliders-toggle-inner {
  color: $white;
  font-size: $seize;
}

.alias-foire-aux-questions {
  .rl_sliders-toggle-inner.nn_sliders-toggle-inner {
    font-size: $vingt-deux;
  }
}

.accordion-inner.panel-body {
  font-family: robotomedium;
  color: $purple1;
}