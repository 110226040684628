// override Bootstrap here

// stylelint-disable value-keyword-case
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            Arial;
$font-size-base: 1.4rem;


// variable pour le breadcrumb
$breadcrumb-item-padding:           .2rem;
$breadcrumb-divider:                quote(">");

$border-radius:               .8rem;
$border-radius-lg:            1.6rem;
$border-radius-sm:            .4rem;

$font-weight-thin:           100;
$font-weight-extralight:     200;
$font-weight-light:          300;
$font-weight-normal:         400;
$font-weight-medium:         500;
$font-weight-semibold:       600;
$font-weight-bold:           700;
$font-weight-extrabold:      800;
$font-weight-black:          900;

$input-btn-padding-y:    1.2rem;
$input-btn-padding-x:    2.4rem;
$btn-border-radius:     .4rem;

$input-padding-y:        .8rem;
$input-padding-x:        1.2rem;
$input-height-inner:     (1.6rem * 1.5) + ($input-padding-y * 2) !default;

$input-btn-padding-y-sm:      1.2rem;
$input-btn-padding-x-sm:      1.6rem;

