.box {
  $box-background: $background-color-default;
  $box-content: $content-color-default;
  $box-border-color: $border-color-default;
  $box-radius: .8rem;
  $box-border-width: 1px;
  $box-padding-inline: map-get($spacers, 18);
  $box-padding-block: map-get($spacers, 24);
  $box-padding-inline-sm: map-get($spacers, 14);
  $box-padding-block-sm: map-get($spacers, 12);
  $box-padding-inline-lg: map-get($spacers, 28);
  $box-padding-block-lg: map-get($spacers, 36);

  --box-padding-inline: #{$box-padding-inline};
  --box-padding-block: #{$box-padding-block};

  border-radius: $box-radius;
  background-color: $box-background;
  border: $box-border-width solid $box-border-color;
  padding: var(--box-padding-inline) var(--box-padding-block);
  transition: all ease-in-out .2s;
  font-size: map-get($global-sizes, 14);

  &-action{
    $box-border-color-action-hover: $border-color-action;

    cursor: pointer;
    &:hover, &:active {
      border-color: $box-border-color-action-hover;
    }
    &:focus, &:active {
      outline: solid $box-border-width+1px rgba($box-border-color-action-hover, .5);
    }

    &-title {
      color: $content-color-dark;
      font-size: clamp(map-get($global-sizes, 24), 1.45vw ,map-get($global-sizes, 28));
      font-weight: $font-weight-medium;
    }
  }

  &-title {
    color: $content-color-dark;
    font-size: map-get($global-sizes, 18);
    font-weight: $font-weight-medium;
  }

  &-sm {
    --box-padding-inline: #{$box-padding-inline-sm};
    --box-padding-block: #{$box-padding-block-sm};
  }

  &-lg {
    --box-padding-inline: #{$box-padding-inline-lg};
    --box-padding-block: #{$box-padding-block-lg};
  }
}