.view-simulateur, .view-simulateuremprunt {
  .corps {
    background-color: $beige;
    padding: 4rem 0;
    @include media-breakpoint-up(lg) {
      padding: 4rem;
    }

    em {
      font-size: 1.4rem;
      color: $purple1;
      a {
        color: $blue-light;
      }
    }

    .simulateur {
      &-title {
        font-family: robotobold;
        font-size: $vingt-cinq;
        line-height: 29px;
        color: $purple1;
        margin-bottom: 2rem;
      }

      input, select {
        width: 70%;
        font-family: robotoregular;
        font-size: 1.5rem;
        color: $purple-light;
        line-height: 18px;
        padding: 10px 15px;
        border-radius: 4px;
        border: 1px solid $beige1;
        outline: none;
        margin-bottom: 10px;
        &::placeholder {
          color: $purple1;
        }
      }

      .lead {
        @include media-breakpoint-down(sm) {
          text-align: center;
          input {
            width: 100%;
          }

          button {
            margin-top: 15px;
          }
        }

      }
    }

    .simulateur-block {
      margin-bottom: 3rem;
      &:first-of-type {
        .simulateur-block {
          &-second-title {
            text-align: center;
          }

          &-body {
            width: 100%;

            select {
              @include media-breakpoint-up(md) {
                width: 50%;
              }
              width: 75%;
              margin-bottom: 0;
            }

            .simu_icons {
              width: 25px;
              font-size: 2rem;
              margin-left: 0.5rem;
            }
          }
        }
      }

      &-header {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }

      &-second-title {
        color: $purple1;
        font-size: $quinze;
        font-family: robotobold;
        line-height: 18px;
        background-color: $white;
        padding: 8px 10px;
        width: 100%;
        text-transform: uppercase;
      }

      &-body {
        background-color: $purple1;
        color: $white;
        padding: 10px 20px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        strong {
          font-family: robotobold;
        }
      }
    }

    .calculer-block {
      border-radius: 4px;
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.4);
      padding: 5px 10px;
      cursor: pointer;
      margin-bottom: 2.5rem;
      &-header {
        font-size: $quatorze;
        margin: 10px 0;
        font-family: robotobold;
        display: flex;
        align-items: center;
        span {
          margin-right: 5px;
        }
      }

      &-body {
        font-size: $quatorze;
      }
    }

    .mentions {
      margin-top: 2rem;
      font-style: italic;
      p {
        font-size: $quatorze;
        color: $purple1;
      }
    }

    #calculer {
      @include simuBouton($white, $purple3);
    }

    #reset {
      @include simuBouton($white,  $purple-light)
    }

    .resultat-main {
      &-bloc {
        &:first-of-type {
          display: flex;
          align-items: center;
          ul {
            li {
              background-color: $beige1;
              padding: 3px 10px;
              margin-bottom: 4.5px;
              cursor: pointer;
              &.uk-active {
                background-color: $purple1;
                a {
                  color: $white;
                }
              }
              a {
                color: $purple1;
                text-decoration: none;
                font-family: robotobold;
              }
            }
          }
        }

        &:last-of-type {
          border-radius: 4px;
        }

        &-header {
          background-color: $white;
          color: $purple1;
          padding: 8px 10px;
        }

        &-body {
          background-color: $purple1;
          color: $white;
          padding: 10px 20px;
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
          hr {
            border-color: $white;
            margin: 15px 0;
            margin-left: 10%;
            width: 60%;
          }
        }
      }
    }

    #infos-simu {
      margin: 2rem 0;
      border-radius: 4px;
      border: 1px solid $purple1;
      padding: 5px 10px;
      span {
        margin-right: 5px;
      }
    }

    #actions {
      .btn-simulateur {
        @include simuBouton($white, $purple3);
      }

      #bouton_imprimer {
        @include simuBouton($white,  $purple-light);
      }

      #bouton_envoyer {
        @include simuBouton($white,  $purple-light);
      }

      a {
        color: $blue-light;
      }
    }

    #send_form {
      h2 {
        color: $purple1;
        font-size: 1.5rem;
        font-family: robotobold;
        line-height: 18px;
        padding: 8px 0;
        width: 100%;
        text-transform: uppercase;
      }

      #bouton_envoyer_mail {
        @include simuBouton($white, $purple1);
        padding: 10px 20px;
      }
    }
  }
}

.view-simulateuremprunt {
  .corps {
    .resultat-main-bloc {
      &:first-of-type {
        ul li {
          background-color: #E8E8EB;
          padding: 3px 10px;
          margin-bottom: 6.5px;
          cursor: pointer;
        }
      }
    }

    .second {
      .simulateur-block {
        &-body {
          @include media-breakpoint-up(lg) {
            height: 260px;
          }
        }
      }
    }
  }
}
