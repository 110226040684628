// MEF menu flottant.

#menu-flottant {
  position: fixed;
  height: 100%;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 1;

  a {
    padding: 15px;
    background-color: $purple;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.3);

    span {
      color: $white;
    }
  }
}

.uk-offcanvas-bar {
  background-color: $purple;

  li {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    font-size: $vingt-cinq;
  }
}