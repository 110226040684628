@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'robotoblack';
  src: url('../fonts/roboto-black-webfont.woff2') format('woff2'),
  url('../fonts/roboto-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotobold';
  src: url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
  url('../fonts/roboto-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotoitalic';
  src: url('../fonts/roboto-italic-webfont.woff2') format('woff2'),
  url('../fonts/roboto-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotolight';
  src: url('../fonts/roboto-light-webfont.woff2') format('woff2'),
  url('../fonts/roboto-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotomedium';
  src: url('../fonts/roboto-medium-webfont.woff2') format('woff2'),
  url('../fonts/roboto-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotoregular';
  src: url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
  url('../fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
