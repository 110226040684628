.form-check-input {
  --bs-form-check-bg: #{$white};
  --bs-form-check-border-color: #{$primary};
  width: 2rem;
  height: 2rem;
  margin: 0;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid var(--bs-form-check-border-color);
  color-adjust: exact;
  print-color-adjust: exact;
  position: initial;
  &:checked {
    --bs-form-check-bg: #{$primary};
  }
  &:focus {
    --bs-form-check-border-color: #{lighten($primary, 20%)};
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba($primary,.25);
  }
  &[type=checkbox] {
    border-radius: .4rem;
    &:checked {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
    }
  }
  &[type=radio] {
    border-radius: 50%;
    &:checked {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
    }
  }
}

.input-group {
  & > .form-control:not(:last-child), & > .custom-select:not(:last-child) {
    border-right-color: transparent;
  }
  & > .form-control:not(:first-child), & > .custom-select:not(:first-child) {
    border-left-color: transparent;
  }
  .input-group-append {
    .input-group-text {
      border-left-color: transparent;
    }
  }
  .input-group-prepend {
    .input-group-text {
      border-right-color: transparent;
    }
  }
  &-text {
    background-color: $input-bg;
  }
}

.progress {
  &.is-small {
    height: map-get($global-sizes, 8);
  }
}