// Header

.layout-type-blog {
  .header {
    @include media-breakpoint-up(md) {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $beige1;
    }
  }
}

.header {
  z-index: 2;
  position: relative;
}

.top-header {
  background-color: $purple;

  a {
    font-size: $treize;
    color: $white;
    font-family: robotolight;
    display: flex;
    align-items: center;
  }

  .menu-part-prof {
    ul {
      height: 100%;
    }

    li {
      &.active {
        a {
          background-color: $white;
          color: $blue-light1;

          &:before {
            left: 0;
            right: 0;
          }
        }
      }

      a {
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        display: flex;
        text-decoration: none;
        position: relative;
        transition: all 0.3s ease;
        padding: 7px 15px;
        @include media-breakpoint-up(lg) {
          padding: 7px 25px;
        }

        @include media-breakpoint-down(lg) {
          padding: 7px 5px;
        }

        &:before {
          content: "";
          position: absolute;
          left: 51%;
          right: 51%;
          bottom: 0;
          background: $blue-light1;
          height: 2px;
          -webkit-transition-property: left, right;
          transition-property: left, right;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
        }

        &:hover {
          color: $blue-light1;
        }
      }
    }
  }

  .agences {
    li {
      display: flex;
      align-items: center;
      margin-left: 10px;

      &.active, &:hover {
        a {
          text-decoration: underline;
        }
      }

      .qsn, .agences, .contact {
        padding-left: 23px;
      }

      .qsn {
        background: url(../images/logo-qsn.png) no-repeat left;
      }

      .agences {
        background: url(../images/ico-nos-agences.png) no-repeat left;
      }

      .contact {
        background: url(../images/ico-formulaire.png) no-repeat left;
        background-size: 15px;
      }
    }
  }
}

.middle-header {
  padding: 17px 0;
  background-color: $white;
  @include media-breakpoint-down(sm) {
    border-bottom: 2px solid $blue-light;
  }

  .responsive {
    .comptes a {
      margin-right: 0;
    }

    .logo-responsive {
      img {
        width: 46px;
      }
    }

    .menu {
      li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);

        &:last-child {
          border-bottom: none;
        }

        a {
          color: $purple1;
          font-size: 1.5rem;
          font-family: robotoregular;
          padding: 0 15px 0 5px;
          border-radius: 4px;
          transition: all 0.3s ease;
          @include media-breakpoint-up(lg) {
            padding: 0 25px 0 5px;
          }
        }
      }
    }

    @include media-breakpoint-up(xl) {
      .wsmenucontainer {
        width: 50%;

        .menu {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .wsoffcanvasopener {
        .wsmenu {
          //width: 100%;
        }
      }
      .wsmenu {
        //top: 87px;
      }
    }
  }

  .particuliers {
    height: 100%;

    .mobile-sub {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      li {
        @include media-breakpoint-up(xl) {
          margin-right: 3px;
        }

        &.active {
          .fa-angle-down {
            color: $white;
          }

          a {
            background-color: $purple3;
            color: $white;
          }
        }

        &:hover, &:focus {
          a {
            @media screen and (min-width: 1205px) {
              background-color: $purple3;
              color: $white;
            }

            &:hover {
              &:after {
                transform: rotate(90deg);
              }
            }
          }
        }

        > a {
          position: relative;
          color: $purple1;
          font-size: $quinze;
          font-family: robotoregular;
          padding: 0 5px 0 10px;
          border-radius: 4px;
          transition: all 0.3s ease;

          &:after {
            @media screen and (min-width: 1205px) {
              content: url('../images/chevron-bas.png');
              margin-left: 12px;
              position: relative;
              left: -4px;
            }
          }
        }

        .nav-child {
          @media screen and (min-width: 1205px) {
            padding: 20px;
            min-width: 250px;
          }

          li {
            @media screen and (min-width: 1205px) {
              margin-bottom: 20px;
            }

            &:last-child {
              margin-bottom: 0;
            }

            a {
              position: relative;
              top: 0px;
              color: $purple1;
              font-size: $quinze;
              text-decoration: none;
              padding: 0;
              background-color: transparent;

              &:after {
                display: none;
              }

              &:hover {
                text-decoration: underline;
              }
            }

            &.active {
              a {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    #wsnavtoggle {
      float: right;
    }

    .wsmenucontainer, .wsmenu, .mobile-sub {
      height: 100%;
    }
  }

  @media screen and (max-width: 1200px) {
    .menu {
      li {
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);

        &.parent {

          a {
            color: $purple;
            font-family: robotobold;
            font-size: $vingt;
            padding: 15px;
          }

          ul.small {
            li {
              padding: 10px 0;

              a {
                font-family: robotoregular;
                font-size: $seize;
                padding: 5px 15px;
              }
            }
          }
        }

        &.cache {
          border-bottom: none;

          a {
            @include boutton($white);
            background: linear-gradient(to right, rgba(36, 172, 218, 1) 0%, rgba(1, 193, 218, 1) 100%);
            padding: 15px 25px;
            display: table;
            margin: 0 auto;
            margin-top: 2rem;
          }
        }
      }
    }
  }
  
  .search_recherche {
    position: relative;
    right: 10px;
  }

  .devenir-client {
    @include boutton($white);
    background: url(../images/ico-devenir-client.png) no-repeat 12px center $purple;
    padding: 15px 15px 15px 35px;

    @media (max-width: 1240px) {
      background: url(../images/ico-devenir-client.png) no-repeat 10px center $purple;
      padding: 15px 15px 15px 30px;
      font-size: 1.23rem;
    }
  }

  .comptes {
    a {
      @include boutton($white);
      background: url(../images/ico-mescomptes.png) no-repeat 12px center, linear-gradient(to right, rgba(36, 172, 218, 1) 0%, rgba(1, 193, 218, 1) 100%);
      padding: 15px 15px 15px 35px;
      white-space: nowrap;

      @media (max-width: 1240px) {
        background: url(../images/ico-mescomptes.png) no-repeat 10px center, linear-gradient(to right, rgba(36, 172, 218, 1) 0%, rgba(1, 193, 218, 1) 100%);
        padding: 15px 15px 15px 30px;
        font-size: 1.23rem;
      }
    }
  }

  &-main {
    &-block {
      margin-right: 15px;

      &:first-child {
        img {
          width: 283px;
          @media screen and (max-width: 1270px) {
            width: 225px;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.no-menu {
  width: 50%;
  @include media-breakpoint-up(lg) {
    width: 70%;
  }

  @media(max-width: 500px) {
    width: 35%;
  }
}


