// Breadcrumbs

.breadcrumb {
  margin: 15px 0;
  ul {
    margin-bottom: 0;
    li {
      background: url(../images/chevron-droit-petit.png) no-repeat right;
      padding-right: 14.5px !important;
      margin-right: 6.5px;

      &:first-child {
        img {
          position: relative;
          top: -1px;
        }
      }

      &:last-child {
        background: transparent;
        a, span {
          pointer-events: none;
          color: $purple-light;
          font-size: $dix;
          @include media-breakpoint-up(md) {
            font-size: $quatorze;
          }
        }
      }

      a, span {
        display: flex;
        align-items: center;
        height: 100%;
      }

      a {
        span {
          color: $purple1;
          font-size: $dix;
          font-family: robotoregular;
          @include media-breakpoint-up(md) {
            font-size: $quatorze;
          }
        }
      }
    }
  }
}

.view-article {
  .breadcrumb {
    margin: 0 0 15px;
  }
}
