.tooltip {
  display: none !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1250px;
  }
}

@media only screen and (max-width: 1200px) {
  .wsmobileheader {
    display: flex !important;
    align-items: center;
    height: 51px;
  }

  .animated-arrow {
    position: initial !important;
  }

  .wsmenucontainer.wsoffcanvasopener {
    margin-left: 0 !important;
  }

  .wsmenu-click i {
    margin-top: 20px !important;
    margin-right: 15px !important;
  }

  .animated-arrow {
    margin: 10px 0 0 -10px !important;
    span {
      background: $purple2 !important;
      width: 14px !important;
      right: 0;
      left: 0;
      &:before {
        background: $purple2 !important;
        width: 20px !important;
      }
      &:after {
        background: $purple2 !important;
        width: 18px !important;
      }
    }
  }

  .wsoffcanvasopener {
    .animated-arrow {
      span {
        background: transparent !important;
        &:before {
          top: 0 !important;
        }
        &:after {
          bottom: -0 !important;
          width: 20px !important;
        }
      }
    }
  }
}

.rsform-simple-js-modal-content {
  width: 90%;
  max-height: 90%;
  height: initial !important;
  @media screen and (min-width: 1367px) {
    width: 80%;
  }

  @include media-breakpoint-down(md) {
    max-height: 100%;
  }

  .box {
    border: none;
    padding: 0;
  }
}

.tarteaucitronIconBottomRight {
  bottom: 1rem !important;
  right: 9rem !important;
}

.btn {
  font-size: 1.6rem;
}