// position de module pied de page

.layout-type-blog {
  .footer {
    border-top: 1px solid $beige1;
    margin-bottom: 15px;
    margin-top: 0px;
    padding-top: 80px;
  }
}

.back-to-top {
  position: fixed;
  bottom: 100px;
  right: 20px;
  display: none;
}

.footer {
  margin-top: 85px;
  @include media-breakpoint-down(sm) {
    margin-top: 45px;
  }

  ul {
    list-style: none;
    padding-left: 0;

    &:first-child {
      li {
        &.parent {
          > a {
            margin-bottom: 15px;
          }
        }

        .nav-child {
          &:last-child {
            img {
              width: 131px;
            }
          }
        }
      }
    }

    &:nth-child(2) {
      .nav-child {
        li {
          &:nth-last-child(2) {
            margin-top: 40px;
          }
        }
      }
    }

    li {
      &.current {
        a {
          text-decoration: underline;
        }
      }

      &.parent {
        margin-bottom: 15px;

        img {
          width: 205px;
        }

        > a {
          font-family: robotobold;
          font-size: $seize;
          margin-bottom: 30px;
        }
      }

      a {
        font-size: $quinze;
        font-family: robotomedium;
        color: $purple1;
        margin-bottom: 7px;
        display: block;
      }
    }
  }

  .nous-contacter {
    background: linear-gradient(45deg, #171733 0%, #262646 100%);
    padding: 25px 30px;
    border-radius: 5px;
    position: relative;
    top: -25px;
    width: 255px;
    height: max-content;
    @include media-breakpoint-down(md) {
      top: 0;
      margin-top: 35px;
      width: 60%;
      text-align: center;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;

    }

    h2 {
      color: $blue-light1;
      font-size: $seize;
      font-family: robotobold;
      margin-bottom: 20px;
      @include media-breakpoint-down(sm) {
        font-size: $vingt;
      }
    }

    a, p {
      color: $white;
      font-size: $quinze;
      font-family: robotomedium;
      @include media-breakpoint-down(sm) {
        font-size: $vingt;
      }
    }

    p {
      a {
        &.feedback-text {
		  @include media-breakpoint-up(sm) {
			background: url(../images/ico-formulaire.png) no-repeat 0px center;
		  }
		  @include media-breakpoint-down(sm) {
			background: url(../images/ico-formulaire.png) no-repeat 15px center;
		  }
          padding-left: 30px;
        }

        &.btn {
          @extend .w-100;
          display: table;
          border-radius: 5px;
          border: 1px solid $blue-light1;
          font-size: $quinze;
          font-family: robotomedium;
          text-align: center;
          padding: 5px 10px;
          text-decoration: none;
          margin: auto;
          margin-top: 15px;
          color: $white;
          transition: all 0.3s ease;

          &:hover {
            background-color: $blue-light1;
            color: $white;
            border-color: $blue-light1;
          }

          @include media-breakpoint-down(sm) {
            font-size: $seize;
          }
        }
      }
    }

    span {
      background: url(../images/ico-tel.png) no-repeat left top;
      padding-left: 30px;
    }
  }

  .mentions {
    background-color: $blue-light1;
    padding: 5.5px 0;
    margin-top: 60px;
    @include media-breakpoint-down(sm) {
      margin-top: 45px;
      padding-top: 15px;
      padding-bottom: 15px;
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
          &:after {
            display: none;
          }
        }
      }
    }

    li {
      position: relative;
      padding-right: 9px;
      margin-right: 5px;

      &:after {
        content: '-';
        color: $white;
        position: absolute;
        right: 0;
        top: -3px;
      }

      &:last-child {
        pointer-events: none;

        &:after {
          display: none;
        }
      }
    }

    a, p, span {
      color: $white;
      font-size: $treize;
      font-family: robotolight;
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        font-size: $seize;
      }
    }
  }

  .respFooter {
    padding: 0 10px;

    a {
      color: $white;
      text-decoration: none;
    }

    .menu {
      h2 {
        font-family: robotobold;
        color: $purple;
        font-size: $vingt;
        margin: 45px 0 30px;
      }

      button {
        @include boutton($white);
        position: relative;
        border: none;
        font-size: $vingt-deux;
        font-family: robotolight;

        &:before {
          position: absolute;
          top: 17px;
          left: 17px;
        }

        @include media-breakpoint-down(md) {
          margin: 0 auto;
          width: 60%;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
        }

        img {
          height: 25px;
        }

        &:first-of-type {
          &:before {
            content: url(../images/ico-appstore.png)
          }
        }

        &:last-of-type {
          &:before {
            content: url(../images/ico-google-play.png)
          }
        }
      }
    }
  }
}
