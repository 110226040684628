html {
  font-size: .625rem; // 1rem = 10px with this line (easier calcul)
}

body {
  font-size: 1.6rem; // 1.6rem = 16px, magic!
  font-family: 'Roboto', 'Arial';
}

sup {
  font-size: 60%;
}

ul {
  padding-left: 1.8rem;
  list-style: square;
}

.invalid {
  color: #9d261d;
  font-weight: bold;
}

input.invalid {
  border: 1px solid #9d261d;
}


hr {
  display: block;
  width: 100%;
}
