// Accueil
.view-featured {
  .banner {
    position: relative;

    .moduletable_alertes {
      @include media-breakpoint-up(lg) {
        position: absolute;
      }
      @include media-breakpoint-down(lg) {
        position: relative;
      }
      top: 0;
      right: 0;
      z-index: 1;
      @include media-breakpoint-down(sm) {
        left: 0;
      }

      ul {
        width: 450px;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }

        li {
          &.uk-open {
            a.uk-accordion-title {
              background: url(../images/ico-alerte.png) no-repeat 20px, url(../images/ico-close.png) no-repeat 95%, $orange;
            }
          }
          .uk-accordion-content {
            background-color: $white;
            padding: 30px 20px 45px;
            margin-top: 0;
            border-bottom-left-radius: 4px;
            box-shadow: 0 12px 27px 0 rgba(0,0,0,0.17);
            overflow-x: hidden;
            height: 438px;

            h2 {
              font-size: $vingt;
            }

            h3 {
              font-size: $seize;
            }

            table {
              td {
                border: 1px solid $blue;
                padding: 5px;
              }
            }

            p {
              font-size: $quinze;
              font-family: robotoregular;
              line-height: 20px;
              color: $purple1;

              &.fleche {
                background: url(../images/arrow.png) no-repeat left;
                padding-left: 35px;
              }

              &.orange {
                background-color: transparent;
                font-family: robotobold;
                color: $orange;
                padding-left: 35px;
              }

              &.violet {
                font-family: robotobold;
                color: $purple1;
                margin-bottom: 0;
                padding-left: 35px;
              }

              strong {
                font-family: robotobold;
              }
            }

            a {
              color: $orange;
            }
          }

          a.uk-accordion-title {
            background: url(../images/ico-alerte.png) no-repeat 20px, url(../images/ico-plus.png) no-repeat 95%, $orange;
            color: $white;
            font-family: robotobold;
            padding: 20px 35px 20px 70px;
            font-size: $quinze;
            border-bottom-left-radius: 4px;
            transition: all 0.3s ease;
            @include media-breakpoint-down(sm) {
              padding: 20px 10px;
              font-size: $seize;
              background: url(../images/ico-plus.png) no-repeat 95%, $orange !important;
            }
          }
        }
      }
    }
  }

  .mea {
    @include media-breakpoint-up(md) {
      margin-top: 75px;
    }
    .moduletable_mea-accueil {
      .block {
        padding: 75px;
        @include media-breakpoint-down(sm) {
          padding: 65px 10px 55px;
        }
        &:first-of-type{
          padding-top: 0;
          padding-bottom: 0;
        }
        &:nth-of-type(odd) {
          background-color: $beige;
        }
        &:nth-of-type(even) {
          .row {
            display: flex;
            flex-direction: row-reverse;
          }
        }
        .img {
          @include media-breakpoint-down(lg) {
            height: auto;
          }
        }
        .infos {
          padding-left: 5%;
          padding: 15px 0 15px 5%;
          @include media-breakpoint-down(sm) {
            margin-top: 4rem;
            padding-left: 15px;
          }
          span {
            opacity: 0.5;
            text-transform: uppercase;
            color: $purple1;
            font-size: $quatorze;
            font-family: robotobold;
            margin-bottom: 15px;
            display: block;
            @include media-breakpoint-down(sm) {
              font-size: $seize;
              margin-bottom: 10px;
            }
          }
          h2 {
            color: $purple1;
            font-family: robotobold;
            font-size: $vingt-cinq;
            margin-bottom: 20px;
          }
          p {
            font-size: $quinze;
            color: $purple1;
            margin-bottom: 0;
            padding-right: 30%;
            @include media-breakpoint-down(lg) {
              padding-right: 0;
            }
            @include media-breakpoint-down(sm) {
              font-size: $seize;
              padding-right: 0;
            }
          }
          .bouttons {
            a {
              @include media-breakpoint-down(md) {
                text-align: center;
              }
              @include media-breakpoint-down(sm) {
                width: 100%;
                font-size: $seize !important;
                padding: 20px !important;
              }
              &:nth-of-type(odd) {
                @include boutton($white);
                margin-top: 25px;

              }
              &:nth-of-type(even) {
                @include boutton($purple2);
                display: table;
                margin-top: 25px;
                background: transparent;
                padding: 14px 20px;
                border: 1px solid $purple2;
                transition: all 0.3s ease;
                @include media-breakpoint-down(md) {
                  margin-top: 15px;
                }
                &:hover {
                  opacity: 1;
                  background: $purple2;
                  border-color: $white;
                  color: $white;
                  transform: initial;
                }
              }
            }
          }
        }
      }
    }
  }
}
