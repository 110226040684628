// syntax

/* déclaration d'une fonction

@mixin truc($variable){
	color:$variable;
}

////* appel de la fonction

.class{
	@include truc(#000);
}

/* déclaration d'une procédure

%ma-fonction{
	color:#000;
}

////* appel de la procédure

.class{
	@extend %ma-fonction;
	border:0;
}


// fonctions déjà existantes

 - lighten($color, $amount) : Makes a color lighter.
 - darken($color, $amount) : Makes a color darker.
 - saturate($color, $amount) : Makes a color more saturated.
 - desaturate($color, $amount) : Makes a color less saturated.

Le tout sur ce site : http://sass-lang.com/documentation/Sass/Script/Functions.html

*/

@mixin boutton_article_out($color) {
  color: $color !important;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: $quatorze;
  border-radius: 4px;
  transition-property: transform;
  transition: all 0.3s ease;
  padding: 10px 20px;
  border: 1px solid $color;
  display: inline-block;
  &:hover {
    opacity: 1;
    background: $color;
    border-color: $white;
    color: $white !important;
    transform: initial;
  }
  @include media-breakpoint-up(md) {
    &:hover {
      opacity: 0.7;
    }
  }
}

@mixin boutton_article($color) {
  background: linear-gradient(45deg, #26ABDA 0%, #00C2DA 100%);
  color: $color !important;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 20px;
  font-size: $quatorze;
  border-radius: 4px;
  transition-property: transform;
  transition: all 0.3s ease;
  display: inline-block;
  @include media-breakpoint-up(md) {
    &:hover {
      opacity: 0.7;
    }
  }
}

@mixin boutton($color) {
  background: linear-gradient(45deg, #26ABDA 0%, #00C2DA 100%);
  color: $color;
  text-decoration: none;
  padding: 15px 20px;
  font-size: $treize;
  border-radius: 4px;
  transition-property: transform;
  transition: all 0.3s ease;
  @include media-breakpoint-up(md) {
    &:hover {
     opacity: 0.7;
    }
  }
}

@mixin simuBouton($color, $bg) {
  position: relative;
  color: $color;
  background-color: $bg;
  font-size: 1.5rem;
  font-family: robotoregular;
  padding: 15px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
  &:hover {
    opacity: 0.8;
  }
}

@mixin bouton_formulaire(){
  background-color: $white;
  display: block;
  color: $blue-light1 !important;
  text-align: center;
  padding: 10px;
  transition: all 0.2s ease-in-out;
  &:hover{
    transition: all 0.2s ease-in-out;
    background-color: $blue-light1;
    color: $white !important;
    text-decoration: none;
  }
}

// élément qui prend toute la longueur du site, rajouter un .container dans l'élément qui a cette classe
%centpourcent {
  margin-left: -50vw;
  margin-right: -50vw;
  clear: both;
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw;
}
