.com_multicontact, .view-reclamation {
  .corps {
    background-color: $beige;
    padding: 2rem 0;
    @include media-breakpoint-up(md) {
      padding: 4rem;
    }

    #multicontact, #reclamation {
      p, em {
        font-family: robotoregular;
        font-size: $quinze;
        color: $purple1;
        line-height: 18px;
      }

      .produits {
        .boxCustom p {
          font-size: $douze;
          color: $purple1;
        }

        em {
          font-size: $douze;
          color: $purple-light;
        }
      }

      .informations {
        h1 {
          font-family: robotobold;
          font-size: $vingt-cinq;
          line-height: 29px;
          color: $purple1;
          margin-bottom: 2rem;
        }

        p {
          &:first-of-type {
            font-family: robotobold;
            margin-bottom: 2rem;
          }

          &:nth-of-type(2) {
            margin-bottom: 2rem;
            strong {
              font-family: robotobold;
            }
          }

          &:last-of-type {
            color: $purple-light;
            font-family: robotoregular;
            line-height: 18px;
            font-size: $quinze;
          }
        }
      }

      form {
        a {
          color: $blue-light;
        }

        input#jform_date_naissance {
          border-right: none;
        }

        .field-calendar {
          .input-append {
            display: flex;
            flex-flow: row;
          }

          button {
            background-color: $white;
            border: 1px solid $beige1;
            border-left: none;
            color: $blue-light;
            padding-right: 15px;
            outline: none;
          }
        }

        section {
          padding: 2rem 0 0;
          border-bottom: 1px solid $beige1;
          &:nth-of-type(2) {
            .block {
              &:last-of-type {
                margin-top: 2rem;
              }
            }

            .control-group {
              &:last-of-type {
                display: flex;
                flex-flow: row;
                p {
                  margin-left: 10px;
                }
              }
            }
          }

          &:nth-of-type(3) {
            .block {
              margin-bottom: 2rem;
            }
          }

          &.documents {
            .block {
              .controls {
                display: flex;
                flex-flow: row;
                align-items: center;
                font-size: $quinze;
                font-family: robotoregular;
                color: $purple-light;
                line-height: 18px;

                strong {
                  position: relative;
                  left: 4px;
                  font-family: robotobold;
                }
              }

              label {
                display: block !important;
                background: url(../images/cloud.png) 12px center no-repeat;
                color: $blue2;
                border: 1px solid $blue-light;
                border-radius: 4px;
                margin-right: 15px;
                margin-bottom: 0;
                cursor: pointer;
                padding: 10px 40px;
                transition: all 0.3s ease;
                @include media-breakpoint-up(md) {
                  padding: 10px 45px 10px 42px;
                  margin-right: 35px;
                }
                &:hover {
                  background: url(../images/cloud.png) 12px center no-repeat, lighten($blue-light, 50%);
                }
              }

              input {
                display: none;
              }

              br {
                display: none;
              }

              .info {
                display: none;
                margin: 5% 0;
                padding: 10px 20px;
                overflow: hidden;
                background: $white;
                border-radius: 4px;
              }
            }
          }

          &:nth-last-of-type(2) {
            .boxCustom {
              display: flex;
              flex-flow: row;
              margin-top: 2rem;
            }
          }

          &:last-of-type {
            border-bottom: none;
          }

          h2 {
            color: $blue-light1;
            font-size: $seize;
            font-family: robotobold;
            line-height: 19px;
            margin-bottom: 2rem;
          }

          h3 {
            color: $purple1;
            font-size: $quinze;
            font-family: robotobold;
            line-height: 18px;
            margin-bottom: 1rem;
          }

          .control-group {
            margin-bottom: 2rem;
          }

          .radio {
            label {
              color: $purple1;
              font-size: $quinze;
              font-family: robotoregular;
              line-height: 17px;
              @include media-breakpoint-down(sm) {
                font-size: $quatorze;
              }
            }
          }

          // Input Radio
          .controls {
            // Input Radio.
            input[type='radio'] {
              position: absolute;
              z-index: -1000;
              left: -1000px;
              overflow: hidden;
              clip: rect(0 0 0 0);
              height: 1px;
              width: 1px;
              margin: -1px;
              padding: 0;
              border: 0;

            }

            input[type='radio'] + label {
              line-height: 16px;
              padding-left: 27px;
              display: inline-block;
              background-repeat: no-repeat;
              background-position: 0 0;
              vertical-align: middle;
              cursor: pointer;

            }

            input[type='radio']:checked + label {
              background-position: 0 -16px;
            }

            label {
              background-image: url(../images/radio.png);
              user-select: none;
              margin-left: 2rem;
              &:first-of-type {
                margin-left: 0;
              }
            }
          }

          // Input Checkbox Custom.
          .boxCustom {
            input {
              display: none;
              &:checked + label {
                background-color: $blue-light1;
              }
            }

            label {
              height: 15px;
              width: 15px;
              padding: 6.5px;
              border: 1px solid $purple4;
              border-radius: 2px;
              position: relative;
              cursor: pointer;
              &:before {
                content: url("../images/check.png");
                position: absolute;
                top: -5px;
                left: -1px;
                transform: scale(0.7);
              }
            }

            p {
              margin-left: 10px;
            }
          }

          // Input général
          .controls {
            input[type="text"], input[type="email"], input[type="tel"], select, textarea {
              width: 100%;
              font-family: robotoregular;
              font-size: $quinze;
              color: $purple-light;
              line-height: 18px;
              padding: 10px 15px;
              border-radius: 4px;
              border: 1px solid $beige1;
              outline: none;
              &::placeholder {
                color: $purple-light;
              }
            }
          }

          &:first-of-type {
            padding-top: 0;
          }
        }

        .validation {
          margin-top: 2rem;
          display: flex;
          flex-flow: row;
          button {
            font-family: robotoregular;
            line-height: 15px;
            padding: 18px;
            border: 1px solid $blue-light1;
            border-radius: 4px;
            cursor: pointer;
            display: block;
            width: 100%;
            transition: all 0.3s ease;
            &:hover {
              opacity: 0.6;
            }
            &:first-of-type {
              background: linear-gradient(45deg, #26ABDA 0%, #00C2DA 100%);
              color: $white;
              margin-right: 3rem;
            }

            &:last-of-type {
              color: $blue-light1;
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  &.view-agences .corps {
    background-color: white;
  }
}
