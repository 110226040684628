$height-map : 600px;

.map {

  &-wrapper {
    height: $height-map;
    z-index: 1;

    & + #js-agency-details {
      font-family: Roboto, serif;
      display: none;
      background: white;
      font-size: 14px;
      position: absolute;
      min-height: 20px;
      width: 35%;
      top: 24px;
      left: 36px;
      z-index: 1;
    }
  }

  &-itemlist {
    padding: map-get($spacers, 24) map-get($spacers, 16);
    border-bottom: 1px solid $border-color-default;

    &-popup {
      padding: map-get($spacers, 18) map-get($spacers, 12);
      border: none;
      display: flex;
      align-items: start;
      position: relative;

      .js-close-popup {
        position: absolute;
        right: 12px;
        top: 16px;
        cursor: pointer;
        z-index: 3;
      }

      .wrapper-description {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .description {
          display: flex;
          align-items: start;
          gap: 4px;
        }
      }
    }

    &-wrapper {
      max-height: $height-map;
      overflow: auto;
    }

    &-type1 {
      --title-color: #167CA1;
    }

    &-type2 {
      --title-color: #167CA1;
    }

    &-type3 {
      --title-color: #343C3D;
    }

    &-type4 {
      --title-color: #8E0A39;
    }

    &-title {
      color: #30305C;
      font-weight: $font-weight-bold;
      font-size: map-get($global-sizes, 18);
      margin-bottom: map-get($spacers, 12);
      margin-top: 6px;
    }

    &-description {
      color: #01C1DA;
      font-weight: 500;
    }

    &-univers {
      background-color: $background-color-accent;
      border-bottom: 1px solid $border-color-action;

      &-title {
        font-weight: $font-weight-bold;
        cursor: pointer;
        padding: map-get($spacers, 16) map-get($spacers, 12);
        margin-bottom: 0 !important;
        position: relative;

        span {
          display: flex;
          width: 100%;

          + img {
            position: absolute;
            right: 12px;
            top: 16px;
            transition: all 0.5s ease;
          }
        }

        &.collapsed span + img {
          transform: rotateX(180deg);
        }

        &:not(.collapsed) {
          margin-bottom: 12px;
        }
      }

      &-content {
        padding: 0 map-get($spacers, 16) map-get($spacers, 12);
        font-size: map-get($global-sizes, 14);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .map-wrapper {
    height: 80vw;

    & + #js-agency-details {
      position: initial;
      width: 100%;
    }
  }
}
