.view-article {
  .corps .main {
    margin-left: 0;
    padding: 0;
    a.btn-bnc {
      @include boutton($white);
      color: $white !important;
    }

    .img {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        //background: linear-gradient(45deg, #26ABDA 0%, #00C2DA 100%);
        opacity: 0.1;
        width: 100%;
      }
      img {
        width: 100%;
        object-fit: cover;
        border-radius: 0 0 4px 4px;
        @include media-breakpoint-up(md) {
          height: 250px;
        }
      }
    }

    img {
      @include media-breakpoint-down(sm) {
        object-fit: contain;
      }
    }

    .infos {
      //padding: 40px 95px;
      color: $purple1;
      font-family: robotoregular;
      h1 {
        font-size: $vingt-cinq;
        font-family: robotobold;
        line-height: 29px;
        margin-bottom: 20px;
        color: $blue2;
      }

      h2{
        font-size: $vingt;
        font-weight: bold;
        padding-bottom: 20px;
        color: $blue2;
        @include media-breakpoint-down(sm) {
          img {
            display: table;
            margin: 1rem auto;
          }
        }
      }

      h3 {
        font-size: $dixhuit;
        color: $purple;
        padding-bottom: 15px;
        font-family: robotobold;
      }

      h4 {
        font-size: $seize;
        color: $purple;
        padding-bottom: 10px;
        font-family: robotobold;
      }

      a{
        color: $blue2;
        &[href*=".pdf"] img{
          margin-right: 10px;
        }
      }

      p {
        font-size: $quinze;
        line-height: 18px;
        strong {
          font-family: robotobold;
        }

        .gris {
          color: $purple-light;
        }
      }

      iframe {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
      @include media-breakpoint-down(sm) {
        .uk-subnav {
          li {
            margin-bottom: 10px;
            text-align: center;
            width: 100%;
          }
        }
      }
    }

    .tableau{
      background-color: $white;
      border-collapse: collapse;
      border: 1px solid $purple-light;
      td{
        padding: 10px;
        border: 1px solid $purple-light;
        font-size: $quatorze;
        font-family: robotoregular;
      }

      thead{
        td{
          background-color: $purple4;
          color: $white;
          font-size: $quinze;
          font-family: robotobold;
          p{
            margin: 0;
          }
        }
        th{
          background-color: $purple4;
          padding: 8px;
          text-align: center;
          color: $white;
        }
      }

      @include media-breakpoint-down(lg) {
        border: 0 !important;
        thead{
          display: none;
        }
        tr{
          float: left;
          display: block;
          width: 100% !important;
          border-bottom: 6px solid $white;
        }
        td{
          float: left;
          padding: 8px;
          display: block;
          width: 100% !important;
          text-align: left;
          img{
            margin: 0 !important;
          }
          &:before{
            content:attr(data-label);
            color: $purple4;
            font-size: $seize;
            font-family: robotobold;
            display: block;
            width: 100%;
          }
        }
      }
    }

    table {
      @include media-breakpoint-down(md) {
        width: 100% !important;
        tr {
          display: flex;
          flex-flow: column;
          text-align: center;
          td {
            width: 100% !important;
            text-align: center !important;
          }
        }
      }
    }
  }
  .horaires-block-image {
    @include media-breakpoint-down(md) {
     .uk-subnav {
       justify-content: center;
       li {
         margin-bottom: 15px;
         &:last-child {
           margin-bottom: 0;
         }
       }
     }
    }
  }
  .bouton {
    @include boutton_article($white);
  }
  .bouton_out{
    @include boutton_article_out($purple2);
  }
  .bouton_formulaire{
    @include bouton_formulaire();
  }
  // Protection des donées.
  &.itemid-335 {
    .corps {
      table {
        td {
          border: 1px solid $purple;
          padding: 5px;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    table {
      height: initial;
      td {
        height: initial;
      }
    }
  }
}
