@each $name, $spacer in $spacers {
  .gap-#{$name} {
    gap: $spacer;
  }
}

.gap-4 {
  gap: .4rem;
}

@include media-breakpoint-up(xl) {
  .w-xl-0 {
    width: 0 !important;
  }
}
