.view-agences {

  @include media-breakpoint-down(md) {
    font-size: 14px;

    #menu-flottant {
      z-index: 2;
    }

    .corps {
      padding-top: 0;
    }
  }

  .horaires {
    &-block {
      &:first-of-type {
        margin-bottom: 4rem;
        h1 {
          font-family: robotobold;
          font-size: $vingt-cinq;
          color: $purple1;
          margin-bottom: 20px;
          @include media-breakpoint-down(sm) {
            padding-top: 2rem;
          }
        }
      }

      &:last-of-type {
        background-color: $white;
        padding: 1.5rem;
        margin-top: 4rem;
        @include media-breakpoint-up(md) {
          padding: 3rem;
        }
      }

      &-image {
        @include media-breakpoint-up(md) {
          margin-top: 7rem;
        }
        img {
          width: 100%;
          @include media-breakpoint-up(lg) {
            max-width: inherit;
            width: auto;
          }
        }
      }

      &-legende {
        margin-top: 2rem;
        h2 {
          color: $blue2;
          font-size: $quinze;
          font-family: robotobold;
          line-height: 18px;
          margin: 2rem 0;
        }

        img {
          margin-right: 15px;
          height: 36px;
          object-fit: contain;
        }

        hr {
          margin: 2rem 0;
          border-color: $beige1;
        }

        a {
          color: $blue2;
          font-family: robotobold;
          font-size: $quinze;
          line-height: 18px;
        }
      }
    }
  }

  .gm-style-iw {
    overflow: visible !important;
    .onglet_map {
      position: absolute;
      top: -26px;
      left: 0;
      width: max-content;
      a {
        font-size: $onze;
        padding: 10px;
        background-color: $blue;
        color: $white;
        border-right: 1px solid $white;
        text-decoration: none;
        &.current {
          background-color: $blue-light;
        }
        &:last-of-type {
          border-right: none;
        }
      }
    }

    .informations-agence {
      h4 {
        font-size: $quatorze;
        font-family: robotobold;
        margin-bottom: 10px;
      }

      b {
        font-family: robotobold;
      }

      p {
        font-size: $treize;
      }

      .contact {
        background-color: $beige1;
        color: $grey;
        padding: 10px 20px;
        display: table;
        margin: 0 auto;
        text-decoration: none;
        transition: all 0.3s;
        &:hover {
          background-color: darken($beige1, 10%);
        }
      }
    }

    .informations-agence, .informations-horaires {
      padding: 10px;
      width: 470px;
      min-height: 190px;

      .contacts {
        margin: 10px 0;
      }
    }
    .informations-horaires {
      display: none;
      table tr td:first-child {
        width: 100px;
      }
    }
  }

}

#js-btn-locate-me {
  width: fit-content;
  height: fit-content;
  transition: all 0.3s ease;
  background: linear-gradient(105deg, #26ABDA 21.35%, #01C1DA 78.65%);

  &:hover {
    opacity: 0.7;
  }
}

.btn.btn-filter {
  padding: 12px 16px;
  color: #30305C;
  border: 1px solid #0FB9DB;

  &:hover {
    background: #0FB9DB;
  }

  &.active {
    color: white;
    background: #30305C;
    border-color: #30305C;
  }
}

@include media-breakpoint-down(md) {
  #js-btn-locate-me {
    padding: 12px 12px 13px !important;
  }

  .btn.btn-filter {
    padding: 10px 16px;
    font-size: 14px;
  }

  .ui-days {
    gap: 10px !important;
  }
}
