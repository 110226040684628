@mixin btn-square() {
  aspect-ratio: 1;
  padding: map-get($spacers, 18);
  line-height: 0;
}

.btn-square {
  @include btn-square();
  &-only-mobile {
    @include media-breakpoint-down(lg) {
      @include btn-square();
    }
  }
}

.btn-white {
  @include button-variant($white, $input-border-color);
  color: $content-color-dark
}