.com_recruitment .corps {
  background-color: $beige;
}


.recruitment {
  color: $content-color-dark;

  &-illustration {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 272/77;
  }

  &-process {
    &-wrapper {
      display: flex;
      gap: 1.6rem;
      position: relative;

      &::after {
        content: '';
        height: 3px;
        background-color: $background-color-contrast;
        width: 84%;
        position: absolute;
        top: 5.2rem;
        left: 8%;
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
        padding-left: 3.8rem;
        gap: 4.4rem;

        &::after {
          height: 88%;
          width: 3px;
          top: 6%;
          left: .7rem;
        }
      }
    }

    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 5.6rem;
      text-align: center;
      flex: 1;

      &::after {
        content: '';
        height: 14px;
        width: 14px;
        border-radius: 100%;
        background-color: $background-color-contrast;
        position: absolute;
        top: 5.2rem;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      @include media-breakpoint-down(md) {
        gap: 1.6rem;

        &::after {
          top: 50%;
          left: -3rem;
        }
      }
    }
  }

  &-item {
    transition: background-color ease-in-out 0.3s, border-color ease-in-out 0.3s;

    &::after {
      transition: right ease-in-out .3s;
      content: 'Voir l\'offre';
      display: block;
      background-color: $background-color-default;
      position: absolute;
      top: 50%;
      right: -200px;
      pointer-events: none;
      transform: translate(0, -50%);
      padding: .8rem;
      border-radius: $border-radius-sm;
      font-weight: bold;
      border: 1px solid $border-color;
    }

    &-tag {
      background-color: $background-color-action;
      color: $white;
      padding: .8rem;
      border-radius: $border-radius-sm;
      font-size: 1.2rem;
      transition: background-color ease-in-out 0.3s;

      &.tag-localistation {
        font-weight: $font-weight-medium;
      }
    }

    &:hover {
      background-color: lighten($background-color-action, 40%);
      border-color: $border-color-action;

      &::after {
        right: 18px;
      }

      .recruitment-item-tag {
        background-color: $background-color-contrast;
      }
    }
  }

  &-detail{
    &-title {
      font-size: 3rem;
    }
    &-head {
      background-color: $background-color-default;
      padding: 3.2rem;
      box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.05);
      &.uk-sticky-fixed {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        .recruitment-detail-title {
          font-size: 1.8rem;
        }
        .recruitment-detail-head-extra {
          display: none;
        }
      }
    }
  }

  &-other-offers {
    background: #E6F8FF;
    padding: 3.2rem 4.8rem;
    border-radius: $border-radius-sm;
  }
}